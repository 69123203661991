<template>
  <div class="remains">
    <headed-block></headed-block>
    <content-page-parallax></content-page-parallax>

    <div class="content">
      <div class="top-line1">
        <h1 class="h1-title">
          Шановний <span>{{ name }},</span> цей матеріал для Вас!
        </h1>

        <div @click="backBtn()" class="back-btn">
          <p>До каталогу</p>
          <span>
            <svg
              width="31"
              height="15"
              viewBox="0 0 31 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.4727 7.30823L5.84907 7.30823"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M8.61914 13.0408L3.07295 7.27037L8.61914 1.49997"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="top-line2">
        <p class="text">Залишки</p>

        <div class="search-wrapper"></div>
      </div>

      <div class="filter-section" v-bind:class="[{ active: isOpenFilter }]">
        <div class="filter-wrapper">
          <div class="drop-list-btn-wrapper">
            <div
              @click="openDropListF('1')"
              v-bind:class="[{ active: openDropList === '1' && openMainBlock }]"
              class="drop-list-btn production-drop-btn"
            >
              <p>
                Препарати
                <span v-if="checkedDrugs.length !== 0">{{
                  checkedDrugs.length
                }}</span>
                <span v-if="checkedDrugs.length === 0">Всі</span>
              </p>
              <span class="arrow">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7L0.669873 0.863636L9.33013 0.863637L5 7Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>

            <div
              @click="openDropListF('2')"
              v-bind:class="[{ active: openDropList === '2' && openMainBlock }]"
              class="drop-list-btn them-drop-btn"
            >
              <p>
                Теми
                <span v-if="checkedTopics.length !== 0">{{
                  checkedTopics.length
                }}</span>
                <span v-if="checkedTopics.length === 0">Всі</span>
              </p>
              <span class="arrow">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7L0.669873 0.863636L9.33013 0.863637L5 7Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>

            <div
              @click="openDropListF('3')"
              v-bind:class="[{ active: openDropList === '3' && openMainBlock }]"
              class="drop-list-btn category-drop-btn"
            >
              <p>
                Категорії
                <span v-if="checkedCategory.length !== 0">{{
                  checkedCategory.length
                }}</span>
                <span v-if="checkedCategory.length === 0">Всі</span>
              </p>
              <span class="arrow">
                <svg
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7L0.669873 0.863636L9.33013 0.863637L5 7Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>

          <transition name="fade">
            <div class="drop-list-block" v-if="openMainBlock">
              <div v-if="openDropList === '1'" class=" drop-list-production">
                <label class="container" @click="selectAll1">
                  <p v-html="all"></p>
                  <input
                    @click="updateFilterArticle()"
                    type="checkbox"
                    v-model="allSelected1"
                  />
                  <span class="checkmark"></span>
                </label>

                <label
                  class="container"
                  v-for="(key, value) in filters.drugs"
                  v-bind:class="[{ dis: key.disabled }]"
                >
                  <p v-html="key.filterName"></p>
                  <input
                    @click="select1()"
                    type="checkbox"
                    v-model="checkedDrugs"
                    :value="`${key.filterName}`"
                    :disabled="key.disabled === true"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div v-if="openDropList === '2'" class=" drop-list-them">
                <label class="container" @click="selectAll2">
                  <p v-html="all"></p>
                  <input
                    @click="updateFilterArticle()"
                    type="checkbox"
                    v-model="allSelected2"
                  />
                  <span class="checkmark"></span>
                </label>

                <label
                  class="container"
                  v-for="(key, value) in filters.topics"
                  v-bind:class="[{ dis: key.disabled }]"
                >
                  <p v-html="key.filterName"></p>
                  <input
                    @click="select2()"
                    type="checkbox"
                    v-model="checkedTopics"
                    :disabled="key.disabled === true"
                    :value="`${key.filterName}`"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>

              <div v-if="openDropList === '3'" class=" drop-list-category">
                <label class="container" @click="selectAll3">
                  <p v-html="all"></p>
                  <input
                    @click="updateFilterArticle()"
                    type="checkbox"
                    v-model="allSelected3"
                  />
                  <span class="checkmark"></span>
                </label>

                <label
                  class="container"
                  v-for="(key, value) in filters.categories"
                  v-bind:class="[{ dis: key.disabled }]"
                >
                  <img
                    v-if="key.id !== ''"
                    :class="'icon' + key.id + ' icon'"
                    :src="require(`../assets/img/page/` + key.id + '.png')"
                    alt="icon"
                  />
                  <p v-html="key.filterName"></p>
                  <input
                    @click="select3()"
                    type="checkbox"
                    :disabled="key.disabled === true"
                    v-model="checkedCategory"
                    :value="`${key.filterName}`"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </transition>
        </div>

        <div
          class="production-chose-wrapper chose-wrapper"
          v-if="checkedDrugs.length !== 0"
        >
          <div
            class="item"
            v-for="(key, value) in filters.drugs"
            v-show="checkedDrugs.includes(key.filterName)"
          >
            <p v-html="key.filterName"></p>
            <label class="container">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5L6.5 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
                <path
                  d="M6.5 6.5L1 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
              </svg>
              <input
                @click="select1()"
                type="checkbox"
                v-model="checkedDrugs"
                :value="`${key.filterName}`"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div
          class="them-chose-wrapper chose-wrapper"
          v-if="checkedTopics.length !== 0"
        >
          <div
            class="item"
            v-for="(key, value) in filters.topics"
            v-show="checkedTopics.includes(key.filterName)"
          >
            <p v-html="key.filterName"></p>
            <label class="container">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5L6.5 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
                <path
                  d="M6.5 6.5L1 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
              </svg>
              <input
                @click="select2()"
                type="checkbox"
                v-model="checkedTopics"
                :value="`${key.filterName}`"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <div
          class="category-chose-wrapper chose-wrapper"
          v-if="checkedCategory.length !== 0"
        >
          <div
            class="item"
            v-for="(key, value) in filters.categories"
            v-show="checkedCategory.includes(key.filterName)"
          >
            <img
              v-if="key.id !== ''"
              :class="'icon' + key.id + ' icon'"
              :src="require(`../assets/img/page/` + key.id + '.png')"
              alt="icon"
            />
            <p v-html="key.filterName"></p>
            <label class="container">
              <svg
                width="7"
                height="7"
                viewBox="0 0 7 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 6.5L6.5 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
                <path
                  d="M6.5 6.5L1 1"
                  stroke="#005A85"
                  stroke-linecap="round"
                />
              </svg>
              <input
                @click="select3()"
                type="checkbox"
                v-model="checkedCategory"
                :value="`${key.filterName}`"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="table-wrapper">
        <table border="0">
          <thead>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
            <tr>
              <td>Дистриб'ютор</td>
              <td>Склад</td>
              <td>Бренд</td>
              <td>Препарат</td>
              <td>Jul 15</td>
              <td>Jul 22</td>
              <td>Jul 29</td>
              <td>Aug 01</td>
              <td>SAP</td>
            </tr>
          </tbody>
        </table>
      </div>

      <subscribe-block></subscribe-block>
    </div>

    <footer-block></footer-block>

    <preloader v-if="preload"></preloader>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "remains",
  data: () => ({
    preload: false,

    category: [
      {
        name: "Педіатр",
        params: "pediatrician",
        id: "1",
        title: "Для педіатрів - Rx-портал Тева"
      },
      {
        name: "Терапевт",
        params: "therapist",
        id: "2",
        title: "Для терапевтів - Rx-портал Тева"
      },
      {
        name: "Отоларинголог",
        params: "otolaryngologist",
        id: "3",
        title: "Для отоларингологів - Rx-портал Тева"
      },
      {
        name: "Невропатолог",
        params: "neurologist",
        id: "4",
        title: "Для невропатологів - Rx-портал Тева"
      },
      {
        name: "Пульмонолог",
        params: "pulmonologist",
        id: "5",
        title: "Для пульмонологів - Rx-портал Тева"
      },
      {
        name: "Фармацевт",
        params: "pharmacist",
        id: "6",
        title: "Для фармацевтів - Rx-портал Тева"
      },
      {
        name: "Дистриб’ютор",
        params: "distributor",
        id: "7",
        title: ""
      },
      {
        name: "Психіатр",
        params: "psychiatrist",
        id: "8",
        title: "Для психіатрів - Rx-портал Тева"
      },
      {
        name: "Кардіолог",
        params: "cardiologist",
        id: "9",
        title: "Для кардіологів - Rx-портал Тева"
      },

      {
        name: "Травматолог",
        params: "traumatologist",
        id: "10",
        title: "Для травматологів - Rx-портал Тева"
      },

      {
        name: "Ревматолог",
        params: "rheumatologist",
        id: "11",
        title: "Для ревматологів - Rx-портал Тева"
      },
      {
        name: "Гінеколог",
        params: "gynecologist",
        id: "12",
        title: "Для гінекологів - Rx-портал Тева"
      },

      {
        name: "Ендокринолог",
        params: "endocrinologist",
        id: "13",
        title: "Для ендокринологів - Rx-портал Тева"
      },

      {
        name: "Дерматолог",
        params: "dermatologist",
        id: "14",
        title: "Для дерматологів - Rx-портал Тева"
      },

      {
        name: "Хірург",
        params: "surgeon",
        id: "15",
        title: "Для хірургів - Rx-портал Тева"
      },
      {
        name: "Інфекціоніст",
        params: "infectious",
        id: "16",
        title: "Для інфекціоністів - Rx-портал Тева"
      }
    ],
    name: "Педіатр",
    forAxios: "",

    all: "Всі",
    openDropList: "",
    openMainBlock: false,
    checkedDrugs: [],
    checkedTopics: [],
    checkedCategory: [],
    filters: {},

    isOpenFilter: false,

    page: "",
    allSelected1: true,
    allSelected2: true,
    allSelected3: true,

    activePage: 1,

    data: [],

    paging: {},

    color: ["color1", "color2", "color3"],

    colorDis: ["disColor1", "disColor2", "disColor3", "disColor4"]
  }),

  beforeMount() {
    // this.preload = true;

    this.category.forEach(el => {
      if (el.params === this.$route.params.category) {
        this.name = el.name;
        this.forNav = el.params;
        this.forAxios = el.id;
      }
    });
  },
  mounted() {
    document.querySelector('meta[property="og:image"]').content =
      this.$root.globalUrl + "/assets/img/teva_logo_for_site.jpg";
    document.title = "Rx-портал Тева Україна";
  },

  methods: {
    openFilterBlock() {
      this.isOpenFilter = !this.isOpenFilter;
    },

    openDropListF(id) {
      if (id === this.openDropList) {
        this.openDropList = "";
        this.openMainBlock = false;
      } else {
        this.openDropList = id;
        this.openMainBlock = true;
      }
    },

    select1() {
      if (this.activePage > 1) {
        this.updateFilterArticle();
        this.$router.push({
          name: "page",
          params: { category: this.$route.params.category, pageNum: "1" }
        });
      } else {
        this.updateFilterArticle();
      }
      this.allSelected1 = false;
    },

    select2() {
      if (this.activePage > 1) {
        this.updateFilterArticle();
        this.$router.push({
          name: "page",
          params: { category: this.$route.params.category, pageNum: "1" }
        });
      } else {
        this.updateFilterArticle();
      }
      this.allSelected2 = false;
    },

    select3() {
      if (this.activePage > 1) {
        this.updateFilterArticle();
        this.$router.push({
          name: "page",
          params: { category: this.$route.params.category, pageNum: "1" }
        });
      } else {
        this.updateFilterArticle();
      }
      this.allSelected3 = false;
    },

    selectAll1() {
      this.checkedDrugs = [];

      if (!this.allSelected1) {
        this.filters.drugs.forEach(el => {
          if (el.disabled === false) {
            this.checkedDrugs.push(el.filterName);
          }
        });
      }
    },

    selectAll2() {
      this.checkedTopics = [];

      if (!this.allSelected2) {
        this.filters.topics.forEach(el => {
          if (el.disabled === false) {
            this.checkedTopics.push(el.filterName);
          }
        });
      }
    },

    selectAll3() {
      this.checkedCategory = [];
      if (!this.allSelected3) {
        this.filters.categories.forEach(el => {
          if (el.disabled === false) {
            this.checkedCategory.push(el.filterName);
          }
        });
      }
    },

    backBtn() {
      this.$router.push({
        name: "page",
        params: { pageNum: "1", category: this.$route.params.category }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$fontB: "Commissioner-Bold";
$fontM: "Commissioner-Medium";
$fontR: "Commissioner-Regular";
$fontL: "Commissioner-Light";
$fontDemi: "Commissioner-Medium";
.remains {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .content {
    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 2;

    margin: 95px auto 40px auto;
    padding: 0 10px;
    max-width: 1128px;
    width: 100%;

    min-height: calc(100vh - 239px);
    height: 100%;

    .top-line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .h1-title {
        margin: 0 7px 0 7px;
        font-family: $fontR;
        color: #545454;
        font-size: 32px;
        @media screen and (max-width: 670px) {
          font-size: 18px;
          margin: 0 7px 15px 7px;
        }
        @media screen and (max-width: 570px) {
          margin: 0 0 15px 0;
        }

        span {
          text-decoration: underline;
          color: #00a03b;
          font-family: $fontDemi;
        }
      }

      .back-btn {
        cursor: pointer;
        background: #aa198b;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 35px;
        height: 63px;
        border-radius: 50px;

        max-width: 255px;
        width: 100%;

        &:hover {
          span {
            transform: translateX(-20px);
          }
        }

        p {
          color: white;
          font-size: 22px;
          font-family: $fontR;
        }

        span {
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
        }
      }
    }

    .top-line2 {
      margin: 30px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        color: #00a03b;
        font-family: $fontDemi;
        font-size: 40px;
      }

      .search-wrapper {
        max-width: 358px;
        width: 100%;
        height: 42px;
        background: #e9e9e9;
        border-radius: 50px;
      }
    }

    .filter-section {
      display: flex;
      flex-direction: column;

      &.filter-section-distributor {
        .drop-list-btn-wrapper {
          justify-content: flex-start;

          .category-drop-btn {
            margin: 0 20px;
          }
        }

        .to-table-btn {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          max-width: 262px;
          width: 100%;
          height: 48px;
          background: #aa198b;
          border-radius: 150px;

          padding: 0 25px;
          text-decoration: none;

          &:hover {
            .icon {
              transform: translateX(10px);
            }
          }

          .text {
            color: white;
            font-size: 22px;
            font-family: $fontR;
          }

          .icon {
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
          }
        }
      }

      .filter-wrapper {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 570px) {
        margin: 0;
      }

      @media screen and (max-width: 425px) {
        display: none;
        &.active {
          display: flex;
        }
      }

      .drop-list-btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 425px) {
          flex-direction: column;
        }

        .drop-list-btn {
          max-width: 356px;
          width: 100%;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 30px;
          background-color: #005a85;
          cursor: pointer;

          @media screen and (max-width: 670px) {
            padding: 0 10px;
            height: 38px;
          }
          @media screen and (max-width: 425px) {
            width: 100%;
            max-width: 425px;
            padding: 0 15px;
            margin: 7px 0;
          }

          &.them-drop-btn {
            margin: 0 20px;
            @media screen and (max-width: 670px) {
              margin: 0 10px;
            }
            @media screen and (max-width: 425px) {
              margin: 7px 0;
            }
          }

          * {
            transition: all 0.3s ease;
          }

          p {
            font-size: 16px;
            font-family: $fontR;
            color: white;
            @media screen and (max-width: 670px) {
              font-size: 14px;
            }

            span {
              color: white;
              display: inline-block;
              padding-left: 10px;
              font-size: 17px;
              font-family: $fontDemi;
              @media screen and (max-width: 670px) {
                font-size: 14px;
                padding-left: 3px;
              }
            }
          }

          &.active {
            background-color: #e4eaed;

            svg {
              path {
                fill: #005a85;
              }

              transform: rotate(180deg);
            }

            p {
              color: #005a85;

              span {
                color: #005a85;
              }
            }
          }
        }
      }

      .drop-list-block {
        margin: 10px 0 0 0;
        width: 100%;
        max-height: 131px;
        height: 100%;
        padding: 30px;
        background: #e4eaed;
        @media screen and (max-width: 425px) {
          max-height: 700px;
          height: 100%;
        }

        .drop-list-production {
          height: 100%;
          max-height: 91px;
          display: flex;
          flex-flow: column wrap;
          max-width: 380px;
          width: 100%;
          @media screen and (max-width: 425px) {
            max-height: 1000px;
          }
        }

        .drop-list-them {
          height: 100%;
          max-height: 91px;
          display: flex;
          flex-flow: column wrap;
          max-width: 380px;
          width: 100%;
          @media screen and (max-width: 425px) {
            max-height: 1000px;
          }
        }

        .drop-list-category {
          height: 100%;
          max-height: 91px;
          display: flex;
          flex-flow: column wrap;
          max-width: 200px;
          width: 100%;
          @media screen and (max-width: 425px) {
            max-height: 1000px;
          }

          .container {
            padding-top: 2px;

            p {
              padding-top: 0;
            }
          }
        }

        .container {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          padding-left: 25px;
          margin: 6px 15px 6px 0;
          cursor: pointer;
          // user-select: none;

          &.dis {
            opacity: 0.3;
          }

          .icon {
            margin-right: 5px;
            display: block;
            width: 14px;
            height: 14px;
          }

          p {
            padding-top: 3px;
            color: #005a85;
            font-family: $fontDemi;
            font-size: 9px;
          }
        }

        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          width: 16px;
          border: 1px solid #005a85;
        }

        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        .container input:checked ~ .checkmark:after {
          display: block;
        }

        .container .checkmark:after {
          left: 4px;
          top: 2px;
          width: 4px;
          height: 7px;
          border: solid #005a85;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .chose-wrapper {
        display: flex;
        flex-flow: row wrap;

        .container {
          transition: all 0.2s ease;

          &:hover {
            transform: scale(1.3);
          }
        }

        .item {
          margin-right: 8px;
          background: #e4eaed;
          border-radius: 50px;
          padding: 5px 14px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          span {
            cursor: pointer;
          }

          p {
            margin-right: 20px;
            color: #005a85;
            font-family: $fontDemi;
            font-size: 9px;
          }

          .container {
            position: relative;
            cursor: pointer;
            // user-select: none;
          }

          .container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
        }

        &.production-chose-wrapper {
        }

        &.category-chose-wrapper {
          img {
            max-width: 14px;
            width: 100%;
            height: auto;
            display: block;
            margin-right: 5px;
          }
        }
      }
    }

    .table-wrapper {
      width: 100%;
      margin: 30px 0;
      display: flex;
      flex-direction: column;

      table {
        width: 100%;

        thead {
          tr {
            td {
              text-align: center;
              color: #545454;
              font-family: $fontM;
              font-size: 13px;
              background: #e6f6ec;

              padding: 16px 0;

              &:first-child {
                border-radius: 23px 0 0 23px;
              }

              &:last-child {
                border-radius: 0 23px 23px 0;
              }
            }
          }
        }

        tbody {
          tr {
            &:nth-child(2n) {
              td {
                background: #ececec;
              }
            }

            td {
              color: #545454;
              font-family: $fontR;
              font-size: 14px;
              text-align: center;
              padding: 15px;
              background: #f2f2f2;

              &:first-child {
                text-align: left;
                font-family: $fontDemi;
              }
            }
          }
        }
      }
    }
  }
}
</style>
